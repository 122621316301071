import { FlexContainer, LeftButton, NotificationContext, PreviewWrapper } from '@mid-react-common/common';
import {
  NameAndRelease,
  ProductDetailsTabs,
  ScrollableContainer,
  VariantFormState,
  VariantThumbnail,
  useFetchingListOfVariants,
  useGeneratedVariantsDataGrid,
  useProductDetailsTabs,
  useRepresentation,
  useRepresentationToInsertReplace,
} from '@mid-react-common/revit-components';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import React, { useContext, useEffect, useState } from 'react';
import text from 'revit.text.json';
import InsertFooter from '../../../components/InsertFooter/InsertFooter';
import DataContext from '../../../context/Data/Data.context';
import { SelectTabContainer } from '../../Screens.styles';
import useInsertInSelectScreen from './useInsertInSelectScreen';
import { AccBridgeDownloadUrlQueryParams, AccBridgeSourceProjectDataQueryParams } from 'mid-api-services';

const SelectScreen: React.FC = () => {
  const { showNotification } = useContext(NotificationContext);
  const {
    currentProductRelease,
    selectedCachedVariant,
    setSelectedCachedVariant,
    updateConfigurableProductInputs,
    configurableProductProperties,
    selectedVariantThumbnailVisible,
    setSelectedVariantThumbnailVisible,
    reFetchCachedVariants,
    setReFetchCachedVariants,
    cachedVariantsList,
    setCachedVariantsList,
    variantFormState,
    setVariantFormState,
    selectedRepresentation,
    setSelectedRepresentation,
    productReleasesListLength,
    postedVariantIdsList,
    selectedIncomingBridgeDataQueryParams,
  } = useContext(DataContext);

  const [thumbnail, setThumbnail] = useState(currentProductRelease?.thumbnail);

  // logic to handle the tabs
  const { tabValue, handleTabChange } = useProductDetailsTabs();

  const incomingAccBridgeDataFromSourceProject: AccBridgeSourceProjectDataQueryParams = {
    sourceProjectId: selectedIncomingBridgeDataQueryParams?.sourceProjectId,
    sourceFolderUrn: selectedIncomingBridgeDataQueryParams?.sourceFolderUrn,
    targetProjectId: selectedIncomingBridgeDataQueryParams?.targetProjectId,
  };

  const incomingAccBridgeDataForDownloadUrl: AccBridgeDownloadUrlQueryParams = {
    sourceProjectId: selectedIncomingBridgeDataQueryParams?.sourceProjectId,
    targetProjectId: selectedIncomingBridgeDataQueryParams?.targetProjectId,
  };

  // logic to fetch the list of variants, in order to feed DataGrid
  const { filteredVariantsListByCurrentRelease, isLoadingVariantsList, handleRefreshVariants } = useFetchingListOfVariants({
    incomingAccBridgeData: incomingAccBridgeDataFromSourceProject,
    currentProductRelease,
    cachedVariantsList,
    reFetchCachedVariants,
    postedVariantIdsList,
    setReFetchCachedVariants,
    showNotification,
  });

  // logic to load data into DataGrid and handle DataGrid selections
  const { dataGridRows, dataGridColumns, rowSelectionModel, onRowSelectionModelChange } = useGeneratedVariantsDataGrid({
    cachedVariantsList,
    productReleaseInputs: configurableProductProperties.inputs,
    reFetchCachedVariants,
    selectedCachedVariant,
    setThumbnail,
    setSelectedCachedVariant,
    setVariantFormState,
    updateConfigurableProductInputs,
    setSelectedRepresentation,
    showNotification,
    formRules: currentProductRelease?.rules?.formRules?.code,
  });

  const { productReleaseRFAOutput } = useRepresentation({
    configurableProductProperties,
    selectedRepresentation,
    setSelectedRepresentation,
  });

  // logic to handle the selected Variant representation dropdown to insert
  const {
    variantRfaRepresentations,
    variantRepresentationDropdownItems,
    selectedVariantRepresentationDropdownItemToInsert,
    selectedVariantRepresentationToInsert,
    handleSelectVariantRfaRepresentationForInsert,
  } = useRepresentationToInsertReplace({
    selectedRepresentation,
    setSelectedRepresentation,
    selectedVariant: selectedCachedVariant,
  });

  // logic to handle the Insert
  const { handleInsertRFA } = useInsertInSelectScreen({
    incomingAccBridgeData: incomingAccBridgeDataForDownloadUrl,
    selectedCachedVariant,
    selectedRepresentation: selectedVariantRepresentationToInsert,
    showNotification,
    setVariantFormState,
  });

  useEffect(() => {
    if (!filteredVariantsListByCurrentRelease) {
      return;
    }

    setCachedVariantsList(filteredVariantsListByCurrentRelease);
  }, [filteredVariantsListByCurrentRelease, setCachedVariantsList]);

  return (
    <SelectTabContainer>
      <ScrollableContainer>
        <FlexContainer justifyContent="space-between">
          {currentProductRelease && (
            <NameAndRelease
              currentProductRelease={currentProductRelease}
              productReleasesListLength={productReleasesListLength}
            />
          )}
          <LeftButton
            variant="text"
            color="secondary"
            onClick={() => setSelectedVariantThumbnailVisible(!selectedVariantThumbnailVisible)}
            endIcon={selectedVariantThumbnailVisible ? <Visibility /> : <VisibilityOff />}
          >
            {text.preview}
          </LeftButton>
        </FlexContainer>

        <PreviewWrapper display={selectedVariantThumbnailVisible ? '' : 'none'}>
          <VariantThumbnail
            tenancyId={currentProductRelease?.tenancyId}
            incomingAccBridgeData={incomingAccBridgeDataForDownloadUrl}
            isLoading={variantFormState === VariantFormState.VARIANT_RFA_OUTPUT_PENDING}
            thumbnail={thumbnail}
            alternateMessage={
              variantFormState === VariantFormState.VARIANT_RFA_OUTPUT_PENDING ? text.generatingNewVariant : undefined
            }
          />
        </PreviewWrapper>

        <ProductDetailsTabs
          tabValue={tabValue}
          handleTabChange={handleTabChange}
          productReleaseRFAOutput={productReleaseRFAOutput}
          productReleaseNotes={currentProductRelease?.notes}
          variantRfaRepresentations={variantRfaRepresentations}
          reFetchCachedVariants={reFetchCachedVariants}
          isLoadingVariantsList={isLoadingVariantsList}
          handleRefreshVariantDataGrid={handleRefreshVariants}
          dataGridRows={dataGridRows}
          dataGridColumns={dataGridColumns}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={onRowSelectionModelChange}
          isProductReleaseConfigurable={currentProductRelease?.isConfigurable}
          authoringAppEngine={currentProductRelease?.context.engine.location}
        />
      </ScrollableContainer>

      <InsertFooter
        handleInsertRFA={handleInsertRFA}
        variantFormState={variantFormState}
        isFormDataValid={!!selectedCachedVariant}
        representationDropdownItems={variantRepresentationDropdownItems}
        handleRfaRepresentationSelection={handleSelectVariantRfaRepresentationForInsert}
        selectedRepresentationDropdownItem={selectedVariantRepresentationDropdownItemToInsert}
      />
    </SelectTabContainer>
  );
};
export default SelectScreen;
