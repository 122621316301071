import { CustomTab, LeftSection, RightButton, RightSection } from '@mid-react-common/common';
import { VariantFormState } from '@mid-react-common/revit-components';
import React, { useContext } from 'react';
import text from 'revit.text.json';
import DataContext from '../../../context/Data/Data.context';
import NavigationContext from '../../../context/Navigation/Navigation.context';
import { commonTestIds } from '../../../tests/helpers/dataTestIds';
import { TabsWrapper } from './CustomizeProduct.styles';
import { ProductCustomizationTabs } from 'context/Data/dataStore';

interface CustomizeProductHeaderProps {
  handleChangeProductClick: () => void;
}

const CustomizeProduct: React.FC<CustomizeProductHeaderProps> = ({ handleChangeProductClick }) => {
  const { variantFormState } = useContext(DataContext);
  const { selectedProductCustomizationTab, setSelectedProductCustomizationTab } = useContext(NavigationContext);

  const handleTabChange = (_: React.SyntheticEvent, newValue: ProductCustomizationTabs) => {
    setSelectedProductCustomizationTab(newValue);
  };

  return (
    <>
      <LeftSection>
        <TabsWrapper value={selectedProductCustomizationTab} onChange={handleTabChange}>
          <CustomTab label={text.tabConfigure} data-testid={commonTestIds.configureTab} />
          <CustomTab
            label={text.tabSelect}
            data-testid={commonTestIds.selectTab}
            disabled={variantFormState === VariantFormState.GENERATING_NEW_VARIANT}
          />
        </TabsWrapper>
      </LeftSection>
      <RightSection>
        <RightButton data-testid={commonTestIds.changeProductButton} variant="text" onClick={handleChangeProductClick}>
          {text.changeProduct}
        </RightButton>
      </RightSection>
    </>
  );
};

export default CustomizeProduct;
