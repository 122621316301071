import { ProductRelease, Variant } from '@adsk/offsite-dc-sdk';
import { areAllVariantOutputsFinished, pollVariant, shouldVariantOutputsPollingContinue } from '@mid-react-common/addins';
import {
  DEFAULT_POLLING_INTERVAL_IN_MS,
  NOTIFICATION_STATUSES,
  ShowNotificationProps,
  StateSetter,
  useCancellablePromise,
} from '@mid-react-common/common';
import { VariantFormStatesWhenVariantExists, VariantFormState, VariantFormStates } from '@mid-react-common/revit-components';
import { useQuery, Query } from '@tanstack/react-query';
import { downloadVariantLogFile, insertRFA } from 'mid-addin-lib';
import { AccBridgeSourceProjectDataQueryParams } from 'mid-api-services';
import { ProductReleaseError, logError } from 'mid-utils';
import { useCallback, useState } from 'react';
import text from 'revit.text.json';
import { DCProductUIExtension } from 'mid-types';
import { getDefaultVariantWithPendingRfa } from './useInsertInConfigureScreen.utils';

interface UseInsertInConfigureScreenProps {
  incomingAccBridgeData?: AccBridgeSourceProjectDataQueryParams;
  currentProductRelease: DCProductUIExtension<ProductRelease> | undefined;
  selectedRepresentation: string | undefined;
  variantFormState: VariantFormStates;
  selectedCachedVariant: Variant | null;
  postVariant: (tenancyId: string, contentId: string, release: number) => Promise<Variant>;
  setVariantFormState: StateSetter<VariantFormStates>;
  setReFetchCachedVariants: StateSetter<boolean>;
  setCurrentVariant: (variant: Variant) => void;
  showNotification: (props: ShowNotificationProps) => void;
}

interface UseInsertInConfigureScreenState {
  polledVariant: Variant | null;
  handleInsertRFA: () => void;
}

const VARIANT_KEY = 'variant';

const useInsertInConfigureScreen = ({
  incomingAccBridgeData,
  currentProductRelease,
  selectedRepresentation,
  variantFormState,
  selectedCachedVariant,
  postVariant,
  setVariantFormState,
  setReFetchCachedVariants,
  setCurrentVariant,
  showNotification,
}: UseInsertInConfigureScreenProps): UseInsertInConfigureScreenState => {
  const cancellablePromise = useCancellablePromise();

  const [postVariantTrigger, setPostVariantTrigger] = useState<boolean>(false);

  const handleVariantFailure = useCallback(
    async (variant: Variant) => {
      if (!currentProductRelease) {
        return;
      }

      try {
        const downloadLocation = await downloadVariantLogFile({
          projectId: variant.tenancyId,
          productId: variant.contentId,
          variantId: variant.variantId,
          productName: currentProductRelease.name,
          incomingAccBridgeData,
        });

        showNotification({
          message: text.successDownloadVariantLog + downloadLocation,
          severity: NOTIFICATION_STATUSES.WARNING,
          // keep the notification alive until user manually closes it (to be able to copy that log path)
          autoDismiss: undefined,
        });
      } catch (downloadFailedVariantLogError) {
        logError(downloadFailedVariantLogError);
        showNotification({
          message: text.failDownloadVariantLog,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      }
    },
    [currentProductRelease, incomingAccBridgeData, showNotification],
  );

  const insertGeneratedVariantRfa = useCallback(
    async (variant: Variant) => {
      try {
        // Insert RFA
        await insertRFA(variant, selectedRepresentation, incomingAccBridgeData);
        showNotification({
          message: text.succeedInsert,
          severity: NOTIFICATION_STATUSES.SUCCESS,
        });

        setReFetchCachedVariants(true);
      } catch (err: any) {
        logError(err);
        showNotification({
          message: text.failInsert,
          severity: NOTIFICATION_STATUSES.ERROR,
        });

        await handleVariantFailure(variant);
      }
    },
    [handleVariantFailure, incomingAccBridgeData, selectedRepresentation, setReFetchCachedVariants, showNotification],
  );

  const handleInsertRFA = useCallback(async () => {
    if (!currentProductRelease?.contentId) {
      throw new ProductReleaseError(text.insertFailedMissingContentId, {
        currentProductRelease,
      });
    }

    const variantExists = VariantFormStatesWhenVariantExists.find((value) => value === variantFormState);
    if (variantExists) {
      if (selectedCachedVariant) {
        return await insertGeneratedVariantRfa(selectedCachedVariant);
      }

      // fixes TRADES-6471
      // check if the default variant has pending RFAs generated, if so, we need to wait for it to finish
      const defaultVariantWithPendingRfa = await getDefaultVariantWithPendingRfa({
        projectId: currentProductRelease.tenancyId,
        productId: currentProductRelease.contentId,
        incomingAccBridgeData,
        productRelease: currentProductRelease.release,
      });

      if (defaultVariantWithPendingRfa) {
        setPostVariantTrigger(true);
        setCurrentVariant(defaultVariantWithPendingRfa);
        return;
      }
    }

    // Post Variant
    try {
      setVariantFormState(VariantFormState.GENERATING_NEW_VARIANT);
      await postVariant(currentProductRelease.tenancyId, currentProductRelease.contentId, currentProductRelease.release);
      setVariantFormState(VariantFormState.INSERTING_VARIANT);
      setPostVariantTrigger(true);
    } catch (err) {
      logError(err);
      showNotification({
        message: text.failInsert,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    }
  }, [
    currentProductRelease,
    insertGeneratedVariantRfa,
    postVariant,
    selectedCachedVariant,
    setVariantFormState,
    showNotification,
    variantFormState,
    setCurrentVariant,
    incomingAccBridgeData,
  ]);

  const pollingVariantAfterInsertRFA = async (): Promise<Variant | undefined> => {
    try {
      setVariantFormState(VariantFormState.GENERATING_NEW_VARIANT);

      const polledVariant = await cancellablePromise(
        pollVariant({
          tenancyId: selectedCachedVariant?.tenancyId,
          contentId: selectedCachedVariant?.contentId,
          variantId: selectedCachedVariant?.variantId,
          representation: selectedRepresentation,
          incomingAccBridgeData,
        }),
      );

      if (polledVariant && areAllVariantOutputsFinished(polledVariant.outputs)) {
        setVariantFormState(VariantFormState.VARIANT_GENERATED);
        setCurrentVariant(polledVariant);
        await insertGeneratedVariantRfa(polledVariant);
      }

      return polledVariant;
    } catch (e) {
      setVariantFormState(VariantFormState.EDITING_NEW_VARIANT);

      logError(e);
      showNotification({
        message: text.failGenerate,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    }
  };

  const { data: polledVariant } = useQuery<Variant | undefined>({
    queryKey: [
      VARIANT_KEY,
      selectedCachedVariant?.tenancyId,
      selectedCachedVariant?.contentId,
      selectedCachedVariant?.variantId,
    ],
    queryFn: pollingVariantAfterInsertRFA,
    enabled: Boolean(postVariantTrigger && selectedCachedVariant),
    refetchInterval: (current: Query<Variant | undefined>) => {
      if (!current.state.data || shouldVariantOutputsPollingContinue(current.state.data)) {
        return DEFAULT_POLLING_INTERVAL_IN_MS;
      }
      setPostVariantTrigger(false);
      return false;
    },
  });

  return {
    handleInsertRFA,
    polledVariant: polledVariant || null,
  };
};

export default useInsertInConfigureScreen;
