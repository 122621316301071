import { AccBridgeIconWithTooltip, FlexContainer } from '@mid-react-common/common';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { AccBridgeDataForIconsWithTooltip } from 'mid-types';
import React from 'react';
import { TreeItemIcon } from './TreeItemIcon';

interface TreeItemLabelProps {
  id: string;
  label: string | number;
  isRoot?: boolean;
  isFolderExpanded?: boolean;
  selectedProjectId: string | null;
  accBridgeData: AccBridgeDataForIconsWithTooltip;
}

export const TreeItemLabel: React.FC<TreeItemLabelProps> = ({
  id,
  label,
  isRoot,
  isFolderExpanded,
  accBridgeData,
  selectedProjectId,
}) => {
  const theme = useTheme();

  return (
    <FlexContainer gap={theme.var.paddingBase / 2} alignItems="center" data-id={id}>
      <FlexContainer alignItems="center">
        <TreeItemIcon isExpanded={isFolderExpanded} isRoot={isRoot} />
      </FlexContainer>
      <Typography variant="body1" noWrap component="div" width="100%">
        {label}
      </Typography>
      <AccBridgeIconWithTooltip accBridgeData={accBridgeData} selectedProjectId={selectedProjectId} />
    </FlexContainer>
  );
};
