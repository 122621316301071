import { AccBridgeSourceProjectDataQueryParams, getDcApiServiceInstance } from 'mid-api-services';
import { Variant, OutputStatus, OutputType } from '@adsk/offsite-dc-sdk';

interface GetDefaultVariantWithPendingRfaParams {
  projectId: string;
  productId: string;
  productRelease: number;
  incomingAccBridgeData?: AccBridgeSourceProjectDataQueryParams;
}

export const getDefaultVariantWithPendingRfa = async ({
  projectId,
  productId,
  productRelease,
  incomingAccBridgeData,
}: GetDefaultVariantWithPendingRfaParams): Promise<Variant | undefined> => {
  const dcApiServiceInstance = getDcApiServiceInstance();

  const productVariants = await dcApiServiceInstance.getVariantsList({
    projectId,
    productId,
    incomingAccBridgeData,
  });

  const currentProductReleaseVariants = productVariants.filter((variant) => variant.release === productRelease);

  // If there is only one variant and it has a pending RFA output, return it
  if (currentProductReleaseVariants.length === 1) {
    const rfaOutputs = currentProductReleaseVariants[0].outputs.filter((output) => output.type === OutputType.RFA);

    if (rfaOutputs.some((output) => output.status === OutputStatus.PENDING)) {
      return currentProductReleaseVariants[0];
    }
  }
};
