import { DynamicContent } from '@adsk/offsite-dc-sdk';
import { productDefinitionEngines } from 'mid-types';
import FusionIcon from '../../assets/svg/fusion_icon.svg?react';
import InventorIcon from '../../assets/svg/inventor_icon.svg?react';
import { useTheme } from '@mui/material/styles';

interface ProductDefinitionIconProps {
  product: DynamicContent;
}

export const ProductDefinitionIcon: React.FC<ProductDefinitionIconProps> = ({ product }) => {
  const theme = useTheme();

  return product.context.engine.location === productDefinitionEngines.FUSION ? (
    <FusionIcon width={theme.var.productDefinitionIconSize} height={theme.var.productDefinitionIconSize} />
  ) : (
    <InventorIcon width={theme.var.productDefinitionIconSize} height={theme.var.productDefinitionIconSize} />
  );
};
