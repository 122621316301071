export const productSelectionTestIds = {
  openSelectedProductReleaseButton: 'open-selected-product-release-button',
};

export const variantThumbnail = {
  thumbnailLoaderContainer: 'variant-thumbnail-loader-container',
  thumbnailContainer: 'variant-thumbnail-container',
};

export const releaseSelectionTestIds = {
  releaseSelectionSection: 'release-selection-section',
};

export const commonTestIds = {
  changeProductButton: 'change-product-button',
  configureTab: 'configure-tab',
  selectTab: 'select-tab',
  insertVariantButton: 'insert-button',
  variantRepresentationDropdown: 'variant-representation-dropdown',
};

export const productDetailsFooterTestId = {
  insertVariantButton: 'insert-button',
  representationDropdown: 'representation-dropdown',
};
