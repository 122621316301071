import { isVariantRfaOutput } from 'mid-utils';
import { LogLevel } from '../../interfaces/log';
import { webview2BrowserApiService } from '../../services';
import { encodeToBase64, logToFile } from '../sharedServices/tools';
import { Variant, VariantRfaOutput, OutputType } from '@adsk/offsite-dc-sdk';
import { getSignedUrlFromDownload } from '../cloudStorage';
import { AccBridgeDownloadUrlQueryParams } from 'mid-api-services';

export const insertRFA = async (
  variant: Variant,
  representation?: string,
  incomingAccBridgeData?: AccBridgeDownloadUrlQueryParams,
): Promise<void> => {
  logToFile('Start insertRFA', LogLevel.Info);

  const { outputs, variantId, tenancyId, contentId, release, inputs } = variant;
  const sourceFolderUrn = variant.context.workspace.folderPath;

  const rfaOutput = outputs.find((output): output is VariantRfaOutput =>
    representation ? output.type === OutputType.RFA && output.modelState === representation : isVariantRfaOutput(output),
  );

  logToFile(`rfaOutput: ${JSON.stringify(rfaOutput)}`, LogLevel.Info);
  // throw error if variant doesn't have a finished rfa output
  if (!rfaOutput) {
    throw new Error(`Variant doesn't contain a RFA output.`);
  }

  if (!rfaOutput.urn) {
    throw new Error(`RFA output of variant doesn't contain an object key.`);
  }

  if (!rfaOutput.modelState) {
    throw new Error(`RFA output of variant doesn't contain a model state.`);
  }

  if (!rfaOutput.category) {
    throw new Error(`RFA output of variant doesn't contain a category.`);
  }

  const targetTenancyId = incomingAccBridgeData?.targetProjectId || tenancyId;
  // retrieve a short-lived signed URL for download the file
  const sourceLocation =
    rfaOutput.urn.indexOf(':\\') > 0
      ? rfaOutput.urn
      : await getSignedUrlFromDownload(tenancyId, rfaOutput.urn, incomingAccBridgeData);
  logToFile(`Get source location, ${sourceLocation}`, LogLevel.Info);
  const engineVersion = variant.context.engine.version;
  logToFile('End insertRFA, call hostApi insertRFA', LogLevel.Info);

  // Encode input parameters
  const encodedInputs = encodeToBase64(JSON.stringify(inputs));

  return webview2BrowserApiService.insertRFA(
    tenancyId,
    contentId,
    variantId,
    sourceLocation,
    rfaOutput.family,
    rfaOutput.category,
    engineVersion,
    release,
    rfaOutput.modelState,
    encodedInputs,
    sourceFolderUrn,
    targetTenancyId,
  );
};
