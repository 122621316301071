import objectHash from 'object-hash';
import { PostVariantInput } from '@adsk/offsite-dc-sdk';

/**
 * Converts a hex string to a base32 string
 * @param hexString hex (base16) string
 * @returns base32 encoded string
 */
export const hexToBase32 = (hexString: string): string => {
  let hash = '';
  for (let i = 0; i < hexString.length; i += 5) {
    // read hexString in chunks of 5 characters (or rest)
    const hexSlice = hexString.substring(i, Math.min(i + 5, hexString.length));
    // convert hex string to base32 string
    let b32Slice = parseInt(hexSlice, 16).toString(32);

    // ensure to not lose leading zeros
    const desiredLength = hexSlice.length === 5 ? 4 : hexSlice.length;
    while (b32Slice.length < desiredLength) {
      b32Slice = '0' + b32Slice;
    }
    // append to hash
    hash += b32Slice;
  }
  return hash;
};

/**
 * calculates deterministic hash of a variant based on referenced product and variant parameters
 * @param contentId id of referenced product
 * @param productRelease version of referenced product
 * @param parameters list of parameters (name-value pairs)
 * @returns deterministic hash
 */
export const calculateVariantHash = <T extends PostVariantInput>(
  tenancyId: string,
  contentId: string,
  productRelease: number,
  parameters: T[],
): string => {
  // collect parameters in key value map
  const parametersMap: Record<string, any> = {};
  parameters.forEach(({ name, value }) => {
    parametersMap[name] = value;
  });

  // collect variant identifying data in an object
  const objectToHash = {
    projectId: tenancyId,
    productId: contentId,
    productRelease,
    parameters: parametersMap,
  };

  // remove all dashes, they are always in the same place and don't add value
  const shortenedTenancyId = tenancyId.replace(/-/g, '');

  // create sha256 hash of object
  const hashedObject: string = objectHash(objectToHash, { algorithm: 'sha256' });

  // hash consists of concatenation of tenancyId and object hash
  const hexString: string = shortenedTenancyId + hashedObject;

  // 5 hex chars are converted into 4 base32 chars
  // this makes the hash significantly shorter
  const hash = hexToBase32(hexString);

  return hash;
};
