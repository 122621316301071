import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { MIDAccBridgeFolder, MetaInfo, AccBridgeProject, AccBridgeDataForIconsWithTooltip } from 'mid-types';
import React from 'react';
import text from '../../addins.text.json';
import { StyledTreeView, TreeItemWrapper } from './MIDTree.styles';
import { GetChildNodes, TreeItem as TreeItemType } from './MIDTree.types';
import useMIDTree from './useMIDTree';
import { TreeItemLabel } from './TreeItemLabel';
import { getACCBridgeProjectsData } from 'mid-utils';

interface MIDTreeProps {
  treeRootElements: TreeItemType[];
  onSelect: (item: TreeItemType, path: MetaInfo[]) => void;
  onNodeToggle?: (nodeIds: string[], treeItems: TreeItemType[]) => Promise<void>;
  getChildNodes: GetChildNodes;
  newElementTreeToInject?: TreeItemType;
  selectedFolderId?: string;
  selectedProjectId: string | null;
  expandedFoldersIds?: string[];
  incomingBridgeFoldersMap: Map<string, MIDAccBridgeFolder> | undefined;
  outgoingBridgeFoldersMap: Map<string, MIDAccBridgeFolder[]> | undefined;
  bridgeProjectsList: AccBridgeProject[] | undefined;
}

const MIDTree: React.FC<MIDTreeProps> = ({
  treeRootElements,
  onSelect,
  getChildNodes,
  newElementTreeToInject,
  expandedFoldersIds,
  onNodeToggle,
  selectedFolderId,
  selectedProjectId,
  incomingBridgeFoldersMap,
  outgoingBridgeFoldersMap,
  bridgeProjectsList,
}) => {
  const { items, onItemClick } = useMIDTree({
    treeRootElements,
    onSelect,
    getChildNodes,
    newElementTreeToInject,
  });

  const renderTree = (children: TreeItemType[]) =>
    children.map((child) => {
      const { id, label, isRoot } = child;
      const childrenNodes = child?.children?.length ? renderTree(child?.children || []) : <></>;
      const isFolderExpanded = expandedFoldersIds?.some((nodeId: string) => nodeId === id);

      const accBridgeDataForTreeItem: AccBridgeDataForIconsWithTooltip = getACCBridgeProjectsData({
        folderUrn: id,
        incomingBridgeFoldersMap,
        outgoingBridgeFoldersMap,
        bridgeProjectsList,
      });

      return (
        <TreeItemWrapper
          key={id}
          nodeId={id}
          label={
            <TreeItemLabel
              id={id}
              label={label}
              isRoot={isRoot}
              isFolderExpanded={isFolderExpanded}
              selectedProjectId={selectedProjectId}
              accBridgeData={accBridgeDataForTreeItem}
            />
          }
          onClick={onItemClick.bind(null, child)}
        >
          {childrenNodes}
        </TreeItemWrapper>
      );
    });

  return (
    <>
      <StyledTreeView
        selected={selectedFolderId}
        expanded={expandedFoldersIds?.map((selectedPath) => selectedPath.split('/').at(-1)!)}
        className="mid-border-shadow"
        {...(onNodeToggle
          ? {
              // override the standard onNodeToggle just to enhance it with the extra 'items' argument
              onNodeToggle: (_, nodeIds) => {
                onNodeToggle(nodeIds, items);
              },
            }
          : {})}
        aria-label={text.fileSystemNavigator}
        defaultCollapseIcon={<ArrowDropDown />}
        defaultExpandIcon={<ArrowRight />}
      >
        {renderTree(items)}
      </StyledTreeView>
    </>
  );
};

export default MIDTree;
