import { FilledFolder, FilledFolderOpen, LockedFolder, LockedFolderOpen } from '@mid-react-common/common';
import { productFolderBrowser } from '../../dataTestIds';

interface TreeItemIconProps {
  isExpanded?: boolean;
  isRoot?: boolean;
}

export const TreeItemIcon: React.FC<TreeItemIconProps> = ({ isExpanded, isRoot }) => {
  if (isRoot) {
    return isExpanded ? (
      <LockedFolderOpen data-testid={productFolderBrowser.treeIconLockedFolderOpen} />
    ) : (
      <LockedFolder data-testid={productFolderBrowser.treeIconLockedFolder} />
    );
  }

  return isExpanded ? (
    <FilledFolderOpen data-testid={productFolderBrowser.treeIconFolderOpen} />
  ) : (
    <FilledFolder data-testid={productFolderBrowser.treeIconFolderOpen} />
  );
};
