import {
  ACCDocSelection,
  ProductDetails,
  ProductFolderBrowser,
  useACCDocSelection,
  useBase64Thumbnail,
} from '@mid-react-common/addins';
import {
  EMPTY_STATE_ILLUSTRATION_TYPES,
  FlexContainer,
  LocationContainer,
  MIDEmptyState,
  PreviewImage,
  PreviewInProgressMessageWrap,
  PreviewLoader,
  PreviewWrapper,
  useAccBridge,
} from '@mid-react-common/common';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import ReleasesSelection from 'components/ReleasesSelection/ReleasesSelection';
import useReleasesSelection from 'components/ReleasesSelection/useReleasesSelection';
import { isDCRfaOutput } from 'mid-utils';
import { useCallback, useContext } from 'react';
import DataContext from '../../context/Data/Data.context';
import text from '../../revit.text.json';
import { productFolderBrowserHeight } from '../../screens/Screens.styles';
import useProductSelection from './useProductSelection';
import { accProjectPlatform } from 'mid-types';

export const ProductSelectionScreen: React.FC = () => {
  const theme = useTheme();
  const {
    currentProductRelease,
    setCurrentProductRelease,
    nonObsoleteProductReleasesList,
    selectedIncomingBridgeDataQueryParams,
  } = useContext(DataContext);
  const {
    accounts,
    accountsLoading,
    projects,
    projectsLoading,
    selectedAccount,
    selectedProject,
    projectsDisabled,
    handleSelectAccount,
    handleSelectProject,
  } = useACCDocSelection();

  const { incomingBridgeFoldersMap } = useAccBridge({
    projectId: selectedProject?.id || null,
    isAccProject: selectedProject?.platform === accProjectPlatform.acc,
  });

  const handleProjectOrFolderChange = useCallback(() => setCurrentProductRelease(undefined), [setCurrentProductRelease]);

  const {
    rootFoldersTreeItems,
    rootFoldersLoading,
    rootFoldersError,
    selectedFolderTreeElement,
    handleFolderClick,
    handleProductClick,
    products,
    incomingAccBridgeProducts,
    productsLoading,
    bridgeProductsLoading,
    productsError,
    selectedProduct,
    expandedFoldersIds,
    areProductsFromAccBridgeIncomingFolder,
  } = useProductSelection({
    selectedProjectId: selectedProject?.id,
    incomingBridgeFoldersMap,
    // reset the product selection once the project is changed
    onProjectOrFolderChange: handleProjectOrFolderChange,
  });

  const { selectedRelease, selectedReleaseStatus, notesForSelectedRelease, handleReleaseSelection } = useReleasesSelection({
    projectId: selectedProject?.id,
    selectedProduct,
    areProductsFromAccBridgeIncomingFolder,
  });

  const { thumbnailInBase64, thumbnailLoading, thumbnailError } = useBase64Thumbnail(
    selectedProject?.id,
    currentProductRelease?.thumbnail,
    selectedIncomingBridgeDataQueryParams,
  );

  const rfaOutput = currentProductRelease?.outputs.find(isDCRfaOutput);
  const isProductReleaseLoading = selectedProduct && !currentProductRelease;

  return (
    <LocationContainer>
      <FlexContainer gap={theme.var.marginBase}>
        <FlexContainer flexDirection="column" flex="2">
          <ACCDocSelection
            maxHeight={productFolderBrowserHeight}
            accounts={accounts || []}
            accountsLoading={accountsLoading}
            projects={projects || []}
            projectsLoading={projectsLoading}
            selectedAccount={selectedAccount}
            selectedProject={selectedProject}
            projectsDisabled={projectsDisabled}
            handleSelectAccount={handleSelectAccount}
            handleSelectProject={handleSelectProject}
          />
          <ProductFolderBrowser
            projectId={selectedProject?.id || null}
            projectPlatform={selectedProject?.platform || null}
            rootFolders={rootFoldersTreeItems}
            rootFoldersLoading={rootFoldersLoading}
            rootFoldersError={rootFoldersError}
            selectedFolderTreeElement={selectedFolderTreeElement}
            products={products}
            incomingAccBridgeProducts={incomingAccBridgeProducts}
            productsLoading={productsLoading || bridgeProductsLoading}
            productsError={productsError}
            selectedProductId={selectedProduct?.contentId}
            onFolderClick={handleFolderClick}
            onProductClick={handleProductClick}
            maxHeight={productFolderBrowserHeight}
            initialExpandedIds={expandedFoldersIds}
          />
          {selectedProduct?.contentId && (
            <ReleasesSelection
              productReleases={nonObsoleteProductReleasesList}
              selectedRelease={selectedRelease}
              handleReleaseSelection={handleReleaseSelection}
            />
          )}
        </FlexContainer>
        <FlexContainer flexDirection="column" flex="1">
          {currentProductRelease?.contentId ? (
            <>
              <PreviewWrapper>
                {thumbnailLoading ? (
                  <PreviewLoader>
                    <CircularProgress
                      size={theme.var.circularProgressSize}
                      thickness={theme.var.circularProgressThickness}
                    />
                    <PreviewInProgressMessageWrap>{text.loadingThumbnail}</PreviewInProgressMessageWrap>
                  </PreviewLoader>
                ) : thumbnailInBase64 ? (
                  <PreviewImage src={`data:image/jpeg;base64,${thumbnailInBase64}`} alt={text.previewImageAltText} />
                ) : (
                  <MIDEmptyState
                    illustrationType={
                      thumbnailError
                        ? EMPTY_STATE_ILLUSTRATION_TYPES.SYSTEM_ERROR
                        : EMPTY_STATE_ILLUSTRATION_TYPES.PAGES_PHOTO
                    }
                    title={thumbnailError ? text.previewNotAvailable : ''}
                  />
                )}
              </PreviewWrapper>
              <ProductDetails
                title={currentProductRelease.name || ''}
                productCategory={rfaOutput?.options?.category || ''}
                productFamily={rfaOutput?.options?.family || ''}
                representations={rfaOutput?.options?.modelStates || []}
                releaseStatus={selectedReleaseStatus}
                releaseNumber={selectedRelease}
                releaseNotes={notesForSelectedRelease}
                isConfigurable={currentProductRelease?.isConfigurable}
                authoringAppEngine={currentProductRelease?.context.engine.location}
              />
            </>
          ) : (
            <>
              {isProductReleaseLoading && (
                <PreviewLoader>
                  <CircularProgress />
                </PreviewLoader>
              )}
            </>
          )}
        </FlexContainer>
      </FlexContainer>
    </LocationContainer>
  );
};
