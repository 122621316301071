import { DynamicContent } from '@adsk/offsite-dc-sdk';
import { GridTwoColumns, Text, useAccBridge } from '@mid-react-common/common';
import Button from '@mui/material/Button';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { accProjectPlatform, AccProjectPlatform, FolderDMPermissionAction, MetaInfo } from 'mid-types';
import React from 'react';
import text from '../../addins.text.json';
import { productFolderBrowser as testIds } from '../../dataTestIds';
import MIDTree from '../MIDTree/MIDTree';
import { TreeItem } from '../MIDTree/MIDTree.types';
import {
  ListItemWrapper,
  ProductFolderBrowserContainer,
  ProductFolderFlexContainer,
  ProductListWrapper,
  TreeWrapper,
} from './ProductFolderBrowser.styles';
import { getChildNodes } from './ProductFolderBrowser.utils';
import { useProductFolderBrowser } from './useProductFolderBrowser';
import { ProductDefinitionIcon } from './ProductIcon';

export interface ProductFolderBrowserProps {
  projectId: string | null;
  projectPlatform: AccProjectPlatform | null;
  rootFolders: TreeItem[];
  rootFoldersLoading: boolean;
  rootFoldersError: Error | null;
  selectedFolderTreeElement?: TreeItem;
  products: DynamicContent[] | undefined;
  incomingAccBridgeProducts?: DynamicContent[];
  productsLoading: boolean;
  productsError: Error | null;
  selectedProductId?: string;
  displayCreateNewFolderButton?: boolean;
  maxHeight: number;
  onFolderClick: (element: TreeItem, path: MetaInfo[]) => void;
  onProductClick?: (contentId: string | undefined) => void;
  onProductDoubleClick?: (product: DynamicContent) => void;
  folderPermissionFilter?: FolderDMPermissionAction;
  initialExpandedIds?: string[];
}

const ProductFolderBrowser: React.FC<ProductFolderBrowserProps> = ({
  projectId,
  projectPlatform,
  rootFolders,
  rootFoldersLoading,
  rootFoldersError,
  selectedFolderTreeElement,
  displayCreateNewFolderButton,
  products,
  incomingAccBridgeProducts,
  productsLoading,
  productsError,
  onFolderClick,
  onProductClick,
  onProductDoubleClick,
  folderPermissionFilter,
  maxHeight,
  selectedProductId,
  initialExpandedIds,
}) => {
  const {
    incomingBridgeFoldersMap,
    outgoingBridgeFoldersMap,
    bridgeProjectsList,
    isLoadingBridgeFolders,
    hasErrors: hasErrorsFetchingAccBridgeFolders,
  } = useAccBridge({ projectId, isAccProject: projectPlatform === accProjectPlatform.acc });

  const {
    emptyFolderDataView,
    emptyProductDataView,
    productsInSelectedFolder,
    handleProductClick,
    handleNewFolderClick,
    handleProductDoubleClick,
    newFolderTreeNodeCreated,
    handleNodeToggle,
    expandedFoldersIds,
  } = useProductFolderBrowser({
    projectId,
    rootFolders,
    rootFoldersLoading: rootFoldersLoading || isLoadingBridgeFolders,
    hasErrorFetchingFolders: !!rootFoldersError || hasErrorsFetchingAccBridgeFolders,
    selectedFolderTreeElement,
    products,
    incomingAccBridgeProducts,
    productsLoading,
    productsError,
    onProductClick,
    onProductDoubleClick,
    initialExpandedIds,
  });

  const folderHasIncomingAccBridgeAutomation = !!(
    selectedFolderTreeElement && incomingBridgeFoldersMap?.get(selectedFolderTreeElement?.id)
  );

  return (
    <ProductFolderBrowserContainer>
      <GridTwoColumns>
        <ProductFolderFlexContainer>
          <Text>{text.foldersInSelectedProject}</Text>
          {displayCreateNewFolderButton && (
            <Button
              variant="outlined"
              size="small"
              onClick={handleNewFolderClick}
              disabled={!selectedFolderTreeElement || folderHasIncomingAccBridgeAutomation}
              data-testid="button-new-folder"
            >
              {text.buttonNewFolder}
            </Button>
          )}
        </ProductFolderFlexContainer>
        <Text>{text.productsInSelectedFolder}</Text>
        {emptyFolderDataView || (
          <TreeWrapper maxHeight={maxHeight}>
            <MIDTree
              selectedFolderId={selectedFolderTreeElement?.id || ''}
              expandedFoldersIds={expandedFoldersIds}
              treeRootElements={rootFolders}
              onSelect={onFolderClick}
              onNodeToggle={handleNodeToggle}
              getChildNodes={getChildNodes(projectId, folderPermissionFilter)}
              newElementTreeToInject={newFolderTreeNodeCreated}
              selectedProjectId={projectId}
              incomingBridgeFoldersMap={incomingBridgeFoldersMap}
              outgoingBridgeFoldersMap={outgoingBridgeFoldersMap}
              bridgeProjectsList={bridgeProjectsList}
            />
          </TreeWrapper>
        )}
        {emptyProductDataView || (
          <ProductListWrapper disablePadding className="mid-border-shadow" maxHeight={maxHeight}>
            {productsInSelectedFolder?.map((product) =>
              onProductClick || onProductDoubleClick ? (
                <ListItemButton
                  data-testid={testIds.productCell}
                  key={product.contentId}
                  selected={product.contentId === selectedProductId}
                  {...(onProductClick
                    ? { onClick: handleProductClick(product.contentId) }
                    : { onDoubleClick: handleProductDoubleClick(product) })}
                >
                  <ListItemIcon>
                    <ProductDefinitionIcon product={product} />
                  </ListItemIcon>
                  <ListItemText>{product.name}</ListItemText>
                </ListItemButton>
              ) : (
                <ListItemWrapper data-testid={testIds.productCell} key={product.contentId}>
                  <ListItemIcon>
                    <ProductDefinitionIcon product={product} />
                  </ListItemIcon>
                  <ListItemText>{product.name}</ListItemText>
                </ListItemWrapper>
              ),
            )}
          </ProductListWrapper>
        )}
      </GridTwoColumns>
    </ProductFolderBrowserContainer>
  );
};
export default ProductFolderBrowser;
