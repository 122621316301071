import { DCInput, ProductRelease, ReleaseStatus, Variant } from '@adsk/offsite-dc-sdk';
import { ConfigurableProductProperties, StateSetter } from '@mid-react-common/common';
import {
  ConfigurableProductActionTypes,
  VariantFormState,
  VariantFormStates,
  configurableProductReducer,
  initialConfigurableProductPropertiesMap,
  initializeConfigurableProductProperties,
} from '@mid-react-common/revit-components';
import { AllAccBridgeQueryParams } from 'mid-api-services';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { DCProductUIExtension } from 'mid-types';
export interface DataStore {
  currentProductRelease: DCProductUIExtension<ProductRelease> | undefined;
  setCurrentProductRelease: StateSetter<DCProductUIExtension<ProductRelease> | undefined>;
  configurableProductProperties: ConfigurableProductProperties;
  updateConfigurableProductInput: (inputToUpdate: DCInput) => void;
  resetConfigurableProductProperties: () => void;
  updateConfigurableProductInputs: (inputToUpdate: DCInput[]) => void;
  selectedCachedVariant: Variant | null;
  setSelectedCachedVariant: StateSetter<Variant | null>;
  variantFormState: VariantFormStates;
  setVariantFormState: StateSetter<VariantFormStates>;
  selectedVariantThumbnailVisible: boolean;
  setSelectedVariantThumbnailVisible: StateSetter<boolean>;
  reFetchCachedVariants: boolean;
  setReFetchCachedVariants: StateSetter<boolean>;
  cachedVariantsList: Variant[];
  setCachedVariantsList: StateSetter<Variant[]>;
  selectedRepresentation: string | undefined;
  setSelectedRepresentation: StateSetter<string | undefined>;
  productReleasesListLength: number | undefined;
  setProductReleasesList: StateSetter<ProductRelease[] | undefined>;
  nonObsoleteProductReleasesList: ProductRelease[] | undefined;
  postedVariantIdsList: string[];
  setPostedVariantIdsList: StateSetter<string[]>;
  selectedIncomingBridgeDataQueryParams: AllAccBridgeQueryParams | undefined;
  setSelectedIncomingBridgeDataQueryParams: StateSetter<AllAccBridgeQueryParams | undefined>;
  sessionId: string;
  setSessionId: StateSetter<string>;
}

export const productCustomizationTabs = {
  CONFIGURE: 0,
  SELECT: 1,
} as const;
export type ProductCustomizationTabs = (typeof productCustomizationTabs)[keyof typeof productCustomizationTabs];

export const useStore = (): DataStore => {
  const [currentProductRelease, setCurrentProductRelease] = useState<ProductRelease | undefined>();
  const [configurableProductPropertiesMap, dispatchConfigurableProductUpdateAction] = useReducer(
    configurableProductReducer,
    initialConfigurableProductPropertiesMap,
  );

  const [selectedCachedVariant, setSelectedCachedVariant] = useState<Variant | null>(null);
  const [variantFormState, setVariantFormState] = useState<VariantFormStates>(VariantFormState.DEFAULT_VARIANT);

  const [selectedVariantThumbnailVisible, setSelectedVariantThumbnailVisible] = useState<boolean>(true);

  const [reFetchCachedVariants, setReFetchCachedVariants] = useState(true);
  const [cachedVariantsList, setCachedVariantsList] = useState<Variant[]>([]);

  const [selectedRepresentation, setSelectedRepresentation] = useState<string | undefined>();

  const [productReleasesList, setProductReleasesList] = useState<ProductRelease[] | undefined>();
  const nonObsoleteProductReleasesList = useMemo(
    () => productReleasesList?.filter((release) => release.status !== ReleaseStatus.OBSOLETE),
    [productReleasesList],
  );
  const [postedVariantIdsList, setPostedVariantIdsList] = useState<string[]>([]);
  const [selectedIncomingBridgeDataQueryParams, setSelectedIncomingBridgeDataQueryParams] = useState<
    AllAccBridgeQueryParams | undefined
  >();
  const [sessionId, setSessionId] = useState<string>('');

  useEffect(() => {
    if (currentProductRelease) {
      initializeConfigurableProductProperties(
        currentProductRelease,
        dispatchConfigurableProductUpdateAction,
        setSelectedRepresentation,
      );
    }
  }, [currentProductRelease]);

  const updateConfigurableProductInput = (inputToUpdate: DCInput) => {
    dispatchConfigurableProductUpdateAction({
      type: ConfigurableProductActionTypes.UPDATE_INPUT,
      payload: inputToUpdate,
    });
  };

  const updateConfigurableProductInputs = (updatedInputs: DCInput[]) => {
    dispatchConfigurableProductUpdateAction({
      type: ConfigurableProductActionTypes.UPDATE_ALL_INPUTS,
      payload: {
        inputs: updatedInputs,
        outputs: currentProductRelease?.outputs || [],
      },
    });
  };

  const resetConfigurableProductProperties = () => {
    if (currentProductRelease) {
      initializeConfigurableProductProperties(
        currentProductRelease,
        dispatchConfigurableProductUpdateAction,
        setSelectedRepresentation,
      );
    }
  };

  return {
    currentProductRelease,
    setCurrentProductRelease,
    configurableProductProperties: {
      inputs: [...configurableProductPropertiesMap.inputs.values()],
      outputs: configurableProductPropertiesMap.outputs,
    },
    selectedCachedVariant,
    setSelectedCachedVariant,
    variantFormState,
    setVariantFormState,
    selectedVariantThumbnailVisible,
    setSelectedVariantThumbnailVisible,
    updateConfigurableProductInput,
    resetConfigurableProductProperties,
    updateConfigurableProductInputs,
    reFetchCachedVariants,
    setReFetchCachedVariants,
    cachedVariantsList,
    setCachedVariantsList,
    selectedRepresentation,
    setSelectedRepresentation,
    productReleasesListLength: productReleasesList?.length,
    setProductReleasesList,
    nonObsoleteProductReleasesList,
    postedVariantIdsList,
    setPostedVariantIdsList,
    selectedIncomingBridgeDataQueryParams,
    setSelectedIncomingBridgeDataQueryParams,
    sessionId,
    setSessionId,
  };
};
