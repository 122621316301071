import { Variant } from '@adsk/offsite-dc-sdk';
import {
  analytics,
  ANALYTICS_ACTIONS,
  NOTIFICATION_STATUSES,
  RevitWebComponentMoniker,
  ShowNotificationProps,
  StateSetter,
} from '@mid-react-common/common';
import { VariantFormState, VariantFormStates } from '@mid-react-common/revit-components';
import { insertRFA } from 'mid-addin-lib';
import { logError } from 'mid-utils';
import { useCallback, useContext } from 'react';
import text from '../../../revit.text.json';
import { AccBridgeDownloadUrlQueryParams } from 'mid-api-services';
import DataContext from 'context/Data/Data.context';

export interface UseInsertInSelectScreenProps {
  incomingAccBridgeData?: AccBridgeDownloadUrlQueryParams;
  selectedCachedVariant: Variant | null;
  selectedRepresentation: string | undefined;
  showNotification: (props: ShowNotificationProps) => void;
  setVariantFormState: StateSetter<VariantFormStates>;
}

interface UseInsertInSelectScreenState {
  handleInsertRFA: () => Promise<void>;
}

const useInsertInSelectScreen = ({
  incomingAccBridgeData,
  selectedCachedVariant,
  selectedRepresentation,
  showNotification,
  setVariantFormState,
}: UseInsertInSelectScreenProps): UseInsertInSelectScreenState => {
  const { sessionId } = useContext(DataContext);
  const handleInsertRFA = useCallback(async (): Promise<void> => {
    if (!selectedCachedVariant) {
      return;
    }

    setVariantFormState(VariantFormState.INSERTING_VARIANT);
    try {
      // Insert RFA
      await insertRFA(selectedCachedVariant, selectedRepresentation, incomingAccBridgeData);

      setVariantFormState(VariantFormState.VARIANT_REPLACED_OR_INSERTED);

      // Segment event
      analytics.track(ANALYTICS_ACTIONS.RVTW.insertVariants, {
        session_id: sessionId,
        sourceMoniker: RevitWebComponentMoniker,
        variantId: selectedCachedVariant.variantId,
        variantName: selectedCachedVariant.name,
        releaseNumber: selectedCachedVariant.release,
        projectId: selectedCachedVariant.tenancyId,
        productId: selectedCachedVariant.contentId,
        numberOfInputs: selectedCachedVariant.inputs.length,
        selectedRepresentation,
      });

      showNotification({
        message: text.succeedInsert,
        severity: NOTIFICATION_STATUSES.SUCCESS,
      });
    } catch (err: unknown) {
      logError(err);
      showNotification({
        message: text.failInsert,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    } finally {
      // Reset the form state to let user insert another representation/variant without the workaround (re-selecting the variant)
      // https://jira.autodesk.com/browse/TRADES-6944
      setVariantFormState(VariantFormState.EXISTING_VARIANT_SELECTED);
    }
  }, [
    incomingAccBridgeData,
    selectedCachedVariant,
    selectedRepresentation,
    sessionId,
    setVariantFormState,
    showNotification,
  ]);

  return {
    handleInsertRFA,
  };
};

export default useInsertInSelectScreen;
